<template>
    <div class="charts-container">
 
 
 
 
     
     
        <div class="left-images">


<img src="../images/home2.png" alt="Left Top Image" />

<div class="text-module">
 
  <p class="description">박지영 교수는 업계에서 주식거래 및 IPO 전문가로 인지도를 굳혔으며 삼성카드, 삼성생명보험 등 국내 기념비적인 IPO 프로젝트에 참여하였다.</p>

<p class="description">2019년 KR 투자증권에서 러브콜을 받고 증권 투자 및 파생상품 업무를 맡았다.
</p>
<p class="description">수학과 통계학을 전공한 이력이 있었기에 박지영 교수는 오랜 시간 동안 인공지능 연산에 깊은 흥미를 가지게 되었으며 삼성증권 재직 기간에는 업계 동료들과 함께 인공지능에 대해 일련의 연구와 개발을 수행하였고 다년간 실전에 적용하여 괄목할만한 성과를 이루었다.
</p>
<p class="description">박지영 교수는 [싱가포르 2019 벤치마크 리서치 어워드]에서 올해의 펀드상, 아시아 증권 베스트 부문상, 올해의 펀드매니저상 등 많은 상과 명예를 받았으며 이후 [블룸버그 비즈니스위크/중국]에서 2020 올해 펀드상(홍콩), 아시아-태평양 지역(닛케이 제외) 5년 우수성과상을 수상하였다.
</p>

</div>





</div> 
  
 
 
 
 
 
 
 
<div class="external-container">
<div class="text-module1">
  <router-link to="/NasD" style=" text-decoration: none;color: #fff;">
      <p>▶다우존스 산업 </p>
    </router-link>
    </div>

     <div class="tradingview-chart-container">
     
     <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
       
     <!-- k线图 -->
     <KvS4></KvS4>
    
     </div></div>
 
    </div>


    <div class="external-container">
<div class="text-module2">
      <p>123</p>
    </div>
     <div class="tradingview-chart-container">
     
     <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
       
      <!-- k线图 -->
      <KvS5></KvS5>
    
     </div></div>
    </div>
 


    <div class="external-container">
<div class="text-module2">
      <p>123</p>
    </div>
     <div class="tradingview-chart-container">
     
     <div class="tradingview-widget-container" style="height: 100%; width: 100%;">
      
      <!-- k线图 -->
      <KvS6></KvS6>
    
     </div></div>
 
    </div>
   
 
 
 
 
 </div>
   </template>
   
   <script>

 
  
 
  import KvS4 from './KvS4.vue';
  import KvS5 from './KvS5.vue';
  import KvS6 from './KvS6.vue';
  export default {
    components: {
     
      KvS4,
      KvS5,
      KvS6,
      
    },




  }
  </script>
   
   <style scoped>



 .image-container {
  justify-content: center; /* 水平居中 */
  /* align-items: center;  */
  display: flex;
  border-radius: 10px;
  /* text-align: center; */
}

.left-images {
  display: flex;
  flex-direction: column; 
  width: 23%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);

  margin: 0 50px;
  padding: 20px 20px;
}
 
 .text-module {
  margin-bottom: 10px; /* 与图片的间距 */
  width: 95%;
  color: #000000;
  margin: 10px auto;
}
 

 
 
 
 
 .container2 {
   display: flex;
   flex-direction: column;
   /* align-items: center; */
 }
 

 
 .container-image2 {
   width: 100%; /* 让图片填充容器的宽度 */
   border-top-left-radius: 8px; /* 顶部圆角 */
   border-top-right-radius: 8px; /* 顶部圆角 */
 }
 
 
 
 
 
 
 
 .charts-container {
   display: flex;
   justify-content: space-around; 
   align-items: center; 
 
 }
 
 .tradingview-chart-container {
 /* margin: 10px; */
   position: relative;
    width: 400px;    
   height: 400px;  
 padding: 5px 0;
 border-radius: 10px;
   margin: 0 auto;  
   border: 1px solid #ddd; 
  
   background-color: #f9f9f9; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
 }
 
 
 
 
 .logo-container {
  position: absolute;
  top: 65%;
  left: 11px;
  bottom: 0;
  z-index: 1000; 
  /* padding: 1px;  */
border-radius: 50px; 
/* padding: 20px;  */
height: 10px;
width: 10px;
}





.logo-container img {
  width: 38px; 
  height: auto;

border-radius: 50px;
}
 
 .external-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start;  */
  }

  .text-module1 {
    background: linear-gradient(to left, #da9e50, #cfbfab);
    color: white; 
    margin: 0 8px;
   width: 160px;
    /* text-align: center;  */
    padding: 0 20px;
    border-radius:10px 10px  0 0 ;
    align-self: flex-start; /* 文字模块自身左对齐 */
  }
  .text-module2 {
    background-color: #a52a2a00; 
    color: rgba(255, 255, 255, 0); 
    margin: 0 8px;
   width: 200px;
    text-align: center; 
    border-radius:10px 10px  0 0 ;
    align-self: flex-start;
  }
 
 
 @media (max-width: 1008px) {
   .charts-container {
     /* flex-direction: row;  */
     flex-direction: column; 
     flex-wrap: wrap; 
   }
   .tradingview-chart-container {
     
     width: 80%;
     height: 180px;  
   }
   .logo-container {
     top: 56%;
    
   }
 
   .external-container{
    display: none;

   }
 
   .left-images {
    background: linear-gradient(to left, #da9e50, #cfbfab);
    color: white; 
  width: 80%;
  
 margin: 0 10px;
}
 

.text-module{
  
    color: white; 
}





 }
 @media (min-width: 1008px) and (max-width: 1408px) {

  .image-container {
  justify-content: center; /* 水平居中 */
  /* align-items: center;  */
  display: flex;
  border-radius: 10px;
  /* text-align: center; */
}

.left-images {
  display: flex;
  flex-direction: column; 
  width: 23%;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);

  margin: 0 50px;
  padding: 20px 20px;
}
 
 .text-module {
  margin-bottom: 10px; /* 与图片的间距 */
  width: 95%;
  color: #000000;
  margin: 10px auto;
}
 

 
 
 
 
 .container2 {
   display: flex;
   flex-direction: column;
   /* align-items: center; */
 }
 

 
 .container-image2 {
   width: 100%; /* 让图片填充容器的宽度 */
   border-top-left-radius: 8px; /* 顶部圆角 */
   border-top-right-radius: 8px; /* 顶部圆角 */
 }
 
 
 
 
 
 
 
 .charts-container {
   display: flex;
   justify-content: space-around; 
   align-items: center; 
 
 }
 
 .tradingview-chart-container {
 /* margin: 10px; */
   position: relative;
    width: 300px;    
   height: 400px;  
 padding: 5px 0;
 border-radius: 10px;
   margin: 0 auto;  
   border: 1px solid #ddd; 
  
   background-color: #f9f9f9; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
 }
 
 
 
 
 .logo-container {
   position: absolute;
   top: 56%;
   left: 10px;
   bottom: 0;
   z-index: 1000; 
   /* padding: 1px;  */
   background-color: rgba(255, 255, 255, 0.5); /* 这里的rgba的最后一个值表示透明度，0.5 表示半透明 */
 
 
 backdrop-filter: blur(5px); 
 
 transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
 border-radius: 10px; 
 /* padding: 20px;  */
 height: 32px;
 }
 .logo-container:hover {
   background-color: rgba(255, 255, 255, 0.7); /* 悬停时增加背景的不透明度 */
   backdrop-filter: blur(15px); 
 }
 
 
 
 
 .logo-container img {
  width: 38px; 
  height: auto;

border-radius: 50px;
}
 
 .external-container {
    display: flex;
    flex-direction: column;
    /* align-items: flex-start;  */
  }

  .text-module1 {
    background: linear-gradient(to left, #da9e50, #cfbfab);
    color: white; 
    margin: 0 8px;
   width: 160px;
    /* text-align: center;  */
    padding: 0 20px;
    border-radius:10px 10px  0 0 ;
    align-self: flex-start; /* 文字模块自身左对齐 */
  }
  .text-module2 {
    background-color: #a52a2a00; 
    color: rgba(255, 255, 255, 0); 
    margin: 0 8px;
   width: 200px;
    text-align: center; 
    border-radius:10px 10px  0 0 ;
    align-self: flex-start;
  }
 




 }
 
 
 
   </style>
   