<template>
  <div class="text-module">
    
    <p>저희 S&J INVEST는 전쟁터와 같은 이 주식시장에서 오직</p>
    <p>고객의 안전한 수익창출을 목표로 국내주식시장에 AI 기술을</p>
    <p>접목한 최첨단 금융기법을 자신있게 소개합니다</p>
    <p>여러분은 더 이상 혼자가 아닙니다</p>
    <p>저희 회사가 훌륭한 동반자가 되어 드리겠습니다!</p>
  </div>
</template>

<script>
export default {
  name: 'TextModule',
  
};
</script>

<style scoped>
.text-module {
  background: linear-gradient(to left, #da9e50, #cfbfab);
  color: white; /* 白色文字 */
  padding: 10px; /* 内边距 */
  border-radius: 20px; /* 边框圆角 */
 margin: 0 50px;
 font-size: 15px;
 width: 460px;
 height: 200px;
 text-align: center;
}




@media (max-width: 768px) {
  .text-module {
  background: linear-gradient(to left, #da9e50, #cfbfab);
  color: white; /* 白色文字 */
  padding: 10px; /* 内边距 */
  border-radius: 20px; /* 边框圆角 */
 margin: 0 20px;
 font-size: 15px;
 width: 86%;
 height: 200px;
 text-align: center;
 margin-left: 20px;
}
  
  
  
  }

  @media (min-width: 1008px) and (max-width: 1400px) {
  .text-module {
  background: linear-gradient(to left, #da9e50, #cfbfab);
  color: white; /* 白色文字 */
  padding: 10px; /* 内边距 */
  border-radius: 20px; /* 边框圆角 */
 margin: 0 20px;
 font-size: 14px;
 width: 86%;
 height: 200px;
 text-align: center;
 margin-left: 20px;
}
  
  
  
  }
</style>

  