<template>
    <div class="content-container">
      <!-- 图片部分 -->
      <div class="image-container">
        <img src="../images/ai.png" alt="Image Description" />
        <div class="image-text">주식시장도 이제는 AI 시대입니다!</div>
      </div>
  
      <!-- 新闻模块部分 -->
      <div class="news-container">
       <HomeNews1></HomeNews1>
      </div>
    </div>
  </template>
  
  <script>
  import HomeNews1 from '../components/HomeNews1.vue';

  export default {
    name: 'ImageNewsComponent',
    components: {
  
        HomeNews1,
},
  };
  </script>
  
  <style scoped>
  .content-container {
    display: flex;
    flex-direction: row; /* 默认横向排列 */
    align-items: flex-start; /* 对齐方式 */
    gap: 20px; /* 两部分之间的间距 */
  }
  
  .image-container {
    flex: 70%; /* 图片容器占据可用空间的一部分 */
    border-radius: 20px;
  margin: 0 20px;
  position: relative;
  }
  .image-text {
  position: absolute; /* 绝对定位，使其能够浮动在图片之上 */
  bottom: 20px; /* 距离容器底部10px */
  right: 30px; /* 距离容器右侧10px */
  background-color: rgb(255, 255, 255); /* 半透明背景增强可读性 */
  color: rgb(211, 147, 63); /* 白色文字 */
  padding: 5px; /* 文字周围的空间 */
  border-radius: 5px; /* 文字背景的圆角 */
}
  
  .news-container {
    flex: 30%; /* 新闻容器占据30%的宽度 */
  background-color: transparent; /* 背景颜色透明 */
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* 边缘阴影 */
  padding: 15px; /* 内边距 */
  border-radius: 20px;
  margin: 0 20px;
  }
  
  img {
    width: 100%; /* 使图片填满容器 */
    height: auto; /* 保持图片的原始宽高比 */
  }
  
  /* 小屏幕时的样式 */
  @media (max-width: 768px) {
    .content-container {
      flex-direction: column; /* 改为纵向排列 */
    }

    .image-container {
    flex: 100%; 
  }
  .news-container{
    width: 84%;
  }

  }
  </style>
  