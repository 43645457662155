<template>
  <br>
  <br>
    <div class="home-page">
   

      <TimeData1 class="TimeData"></TimeData1>

<DataS11></DataS11>

 
<br>

<DataS10></DataS10>

  


<br>
<ImageNewsComponent></ImageNewsComponent>






    </div>
  </template>
  





  <style scoped>

.TimeData{

  display: none;
}


.image-container {
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  display: flex;
  border-radius: 10px;
  /* text-align: center; */
}

.left-images {
  display: flex;
  flex-direction: column; /* 垂直排列图片 */
  width: 30%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: rgb(255, 255, 255);

  margin: 0 50px;
  padding: 0 20px;
}
.right-images {
  display: flex;
  flex-direction: column; /* 垂直排列图片 */
  width: 30%;
  border-radius: 10px;
  margin: 0 50px;
}

.left-images img {
  width: 100%; 
  height: auto;
  margin-bottom:0px; 
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);  */

  margin: 20px 0;
}

.right-image img {
  
  width: 97%; 
  height: auto;
  /* margin: 0 80px; */
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
}



@media (max-width: 1008px) {
  .image-container {
    flex-direction: column; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;
  }

  .left-images {
    width: 80%; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;

  }
  .right-image{
    width: 80%; 
    justify-content: center; 
  align-items: center; 
  display: flex;
  border-radius: 10px;
  }



  .TimeData{

display: block;
}
}


.left-section {
  flex: 1;
  text-align: center; /* 居中文字 */
}

.text-module {
  margin-bottom: 10px; /* 与图片的间距 */
  width: 95%;
  color: #000000;
  margin: 10px auto;
}

/* .title {
  color: rgb(33, 69, 230); 
  margin-bottom: 10px; 
  background-color: #fff;
  border-radius: 20px;
  padding:0 10px;
  font-size: 20px;
} */


</style>

  
  
  <script>

import DataS10 from './DataS10.vue';
import DataS11 from '../components/DataS11.vue';
import ImageNewsComponent from '../components/ImageNewsComponent.vue';
import TimeData1 from './TimeData1.vue';




export default {
  name: 'App',
  components: {
    DataS11,
    DataS10,
    ImageNewsComponent,
    TimeData1
},
}

</script>
