<template>
  <IframeComponent
    class="iframe-sss2"
    :url="iframeUrl"
    :iframeStyle="{ height: '1000px', 'border-radius': '20px', 'overflow': 'hidden' }"
  />
</template>

<script>
import IframeComponent from './IframeComponent.vue'

export default {
  components: {
    IframeComponent,
  },
  data() {
    return {
      iframeUrl: '', // 初始化为空字符串
    }
  },
  methods: {
    updateIframeUrl() {
      
      const urlLargeScreen = 'https://stock.mk.co.kr/';
      
      const urlSmallScreen = 'https://stock.mk.co.kr/domestic?type=kosdaq';

      
      const mediaQuery = window.matchMedia('(max-width: 768px)');
      this.iframeUrl = mediaQuery.matches ? urlSmallScreen : urlLargeScreen;
      
     
      mediaQuery.addEventListener('change', (e) => {
        this.iframeUrl = e.matches ? urlSmallScreen : urlLargeScreen;
      });
    }
  },
  mounted() {
    this.updateIframeUrl();
  },
}
</script>

<style scoped>
.iframe-sss2{
  transform: translateY(-320px) translateX(-402px);
  border-radius: 20px; 
  overflow: hidden; 
}
.iframe-sss2 iframe {
  border-radius: 20px; 
  overflow: hidden; 
}
.iframe-container {
  display: flex;
  justify-content: space-around;
   

 
}
iframe {
  width: 1000px;
  border-radius: 20px; 
  overflow: hidden; 
  clip-path: inset(330px 230px 335px 410px);
overflow: hidden; 

}
@media (max-width: 768px) {
  
  .iframe-sss2{
  transform: translateY(-320px) translateX(-16px);
  border-radius: 20px; 
  overflow: hidden; 
}
.iframe-sss2 iframe {
  border-radius: 20px; 
  overflow: hidden; 
}
.iframe-container {
  display: flex;
  justify-content: space-around;
   

 
}
iframe {
  width: 420px;
  border-radius: 20px; 
  overflow: hidden; 
  clip-path: inset(320px 0px 250px 0px);
overflow: hidden; 

}
}

</style>
