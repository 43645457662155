<template>


    
        <div>
         
          <div class="news-container">

            <div class="news-type-label">
        <p>>최신 뉴스</p>
      </div>







            <div class="news-list">
              <div v-for="news in paginatedNewsData" :key="news.id" class="news-item" @click="goToNews(news.url)">
                <img v-if="news.imgUrl" :src="news.imgUrl" alt="News Image" class="news-image"/>
                <div class="news-text">
                  <h3>{{ news.title }}</h3>
                  <p class="description">{{ news.content }}</p>
                  <div class="info">
                    <span class="author">{{ news.author }}</span>
                    <span class="date">{{ formatDate(news.releaseDate) }}</span>
                  </div>
                </div>
              </div>
            </div>
      
            <div class="pagination">
      <button @click="changePage(currentPage - 1)" :disabled="currentPage === 1">
        <ion-icon name="play-back-outline"></ion-icon>
      </button>
      <button v-for="page in paginatedRange" :key="page" :class="{active: page === currentPage}" @click="page === '...' ? null : changePage(page)">
        {{ page }}
      </button>
      <button @click="changePage(currentPage + 1)" :disabled="currentPage === totalPages">
        <ion-icon name="play-forward-outline"></ion-icon>
      </button>
    </div>
          </div>
        </div>
      </template>
      
      <script>
      import axios from 'axios';
      
      export default {
    
        components:{
           
        },
    
        name: 'NewsData',
        data() {
          return {
            currentPage: 1,
            pageSize: 5,
            totalItems: 0,
            totalPages: 0,
            newsData: [],
            error: null,
            loading: true,
            maxVisiblePages: 5,
        paginatedRange: [],
          };
        },
        computed: {
          paginatedNewsData() {
            return this.newsData;
          },
        },
        mounted() {
          this.fetchNewsData();
          this.updatePaginationRange();
        },
        methods: {
            fetchNewsData() {
      this.loading = true;
      axios.post('https://sj-investing.com/ideal/api/newsList/allNews', {
          title: "",
          current: this.currentPage,
          size: this.pageSize
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .then(response => {
        console.log("Response:", response);
        this.loading = false;
         if (response.data && response.data.code === 200) {
          this.newsData = response.data.data.records;
          this.totalItems = response.data.data.total;
          this.totalPages = Math.ceil(this.totalItems / this.pageSize);
        //   this.newsData = [response.data.data];
          console.log("已获取 News Data:", this.newsData);
          this.updatePaginationRange();
        } else {
          this.error = 'Invalid response from the API';
        }
      })
      .catch(error => {
        this.loading = false;
        console.error('Error fetching news data:', error);
        this.error = error.message || 'Error fetching news data';
      });
    },
          changePage(page) {
            if (page > 0 && page <= this.totalPages) {
              this.currentPage = page;
              this.updatePaginationRange(); 
              this.fetchNewsData();
            }
          },
          goToNews(url) {
            if (url) {
              window.open(url, '_blank');
            }
          },
          formatDate(dateStr) {
            const date = new Date(dateStr);
            return date.toLocaleDateString();
          },
          updatePaginationRange() {
      const totalPages = this.totalPages;
      const currentPage = this.currentPage;
      const maxVisiblePages = this.maxVisiblePages;
      let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
      let endPage = startPage + maxVisiblePages - 1;
    
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = Math.max(1, endPage - maxVisiblePages + 1);
      }
    
      this.paginatedRange = [];
      if (startPage > 1) {
        this.paginatedRange.push(1);
        if (startPage > 2) {
          this.paginatedRange.push('...');
        }
      }
      for (let i = startPage; i <= endPage; i++) {
        this.paginatedRange.push(i);
      }
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) {
          this.paginatedRange.push('...');
        }
        this.paginatedRange.push(totalPages);
      }
    },
        }
      };
      </script>
      
      
      
      <style scoped>
      .pagination button.active {
      color: orange; /* 当前页码的颜色 */
    }
      .news-container {
        display: flex;
        flex-direction: column;
        /* align-items: center;
        */
        justify-content: flex-start;
      width: 500px;
      margin: 0 1px;
        white-space: nowrap; /* 防止文本换行 */
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 在文本被截断的地方显示省略号 */
          
          -webkit-mask-image: linear-gradient(to right, black 20%, transparent 100%);
          mask-image: linear-gradient(to right, black 20%, transparent 100%);
          
      }
      
      .news-list {
        /* width: 30%; */
      }
      
      .news-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        border-radius: 10px;
        justify-content: flex-start;
      }
      
      .news-item:hover {
        background-color: #f0f0f0; /* 高亮颜色 */
      }
      
      .news-image {
        width: 100px;
        height: auto;
        /* margin-right: 10px; */
        justify-content: flex-start;
        margin: 10px 0;
        border-radius: 10px;
      }
      
      .news-text {
        flex-grow: 1;
        margin: 10px 10px;
        
      }
      
      .news-text h3{
      color: #000000;
      
      }
      
      
      .author{
      color: #8a8484;
      font-size: 13px;
      margin-right:20px;
      word-wrap: break-word; 
          overflow-wrap: break-word; 
        
      }
      
      .date{
      color: #8a8484;
      font-size: 12px;
      
      }
      .news-text p{
      color: #505050;
       font-size: 15px;
      }
      
     
      
      
      .pagination {
        display: flex;
        justify-content: center;
        margin-top: 10px;
        /* margin: 50px 50px; */
        
      }
      
      
      
      
      .news-divider {
          border: 0;
          height: 1px;
          background-color: #d3d3d3; /* 灰色 */
          /* margin: 10px 30px; */
          /* width: 80%; */
          justify-content: flex-start;
        }
      
      
      
      
        .pagination button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 16px;
        padding: 5px;
        margin: 0 5px;
      }
      
      .pagination button:hover {
        color: orange;
      }
      
      /* Font Awesome 图标 */
      .pagination i {
        margin-right: 5px;
        color: #000;
      }
      

      @media (max-width: 768px) {
        .news-text h3{
      color: #000000;
      font-size: 10px;
      }
      
      
      .author{
      color: #8a8484;
      font-size: 8px;
      margin-right:20px;
      word-wrap: break-word; 
          overflow-wrap: break-word; 
        
      }
      
      .date{
      color: #8a8484;
      font-size: 8px;
      
      }
      .news-text p{
      color: #505050;
       font-size: 8px;
      }
      



      .news-text {
  flex-grow: 1;
  margin: 10px 10px;
  overflow: hidden; /* 隐藏溢出的内容 */
  text-overflow: ellipsis; /* 在文本被截断的地方显示省略号 */
  white-space: nowrap; /* 防止文本换行 */
  /* 添加max-width或者width来限制文本宽度 */
  max-width: calc(100% - 120px); /* 假设图片宽度为100px且有10px的间距 */
}

.news-text h3, .news-text p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}







      .news-container {
    
          -webkit-mask-image: linear-gradient(to right, rgb(0, 0, 0) 0%, transparent 100%);
          mask-image: linear-gradient(to right, rgb(0, 0, 0) 0%, transparent 100%);
          width: 400px;
      }

      }


      .news-type-label {
        background: linear-gradient(to left, #da9e50, #cfbfab);
    color: #ffffff; /* 文字颜色 */
    padding: 5px 10px; /* 内边距 */
    border-top-left-radius: 15px; 
    border-bottom-right-radius: 15px; 
    margin: 0;
     }


      </style>
        