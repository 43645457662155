<template>
   <div class="charts-container">



<image-layout></image-layout>
    
 
<div class="external-container">
<div class="text-module1">
  <router-link to="/PageData3" style=" text-decoration: none;color: #fff;"> <p>▶국내증시</p></router-link>
     
    </div>

     <div class="tradingview-chart-container">
     
  
         <!-- k线图 -->
         <div class="click-blocker"></div>
     <KvS1 ></KvS1>
    
     </div>
 
    </div>


    <div class="external-container">
<div class="text-module2">
      <p>123</p>
    </div>
     <div class="tradingview-chart-container">
     
     
      <div class="click-blocker"></div>
       <KvS2></KvS2>
    
     </div>
    </div>
 


    <div class="external-container">
<div class="text-module2">
      <p>123</p>
    </div>
     <div class="tradingview-chart-container">
     
     <div class="tradingview-widget-container"  style="height: 100%; width: 100%;">
       
      <div class="click-blocker"></div>
       <KvS3></KvS3>
    
     </div></div>
 
    </div>
   
 





</div>
  </template>
  
  <script>

  import ImageLayout from './ImageLayout.vue';

  import KvS1 from './KvS1.vue';
  import KvS2 from './KvS2.vue';
  import KvS3 from './KvS3.vue';

  export default {
    components: {
      ImageLayout,
      KvS1,
      KvS2,
      KvS3,
     
      
    },




  }
  </script>
  
  
  <style scoped>









.container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.container-image2 {
  width: 100%; 
  border-top-left-radius: 8px; /* 顶部圆角 */
  border-top-right-radius: 8px; /* 顶部圆角 */
}



.external-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }

  .text-module1 {
    background: linear-gradient(to left, #da9e50, #cfbfab);
    color: white; /* 白色文字 */
    margin: 0 8px;
   width: 160px;
    /* text-align: center;  */
    padding: 0 20px;
    border-radius:10px 10px  0 0 ;
    align-self: flex-start; /* 文字模块自身左对齐 */
  }
  .text-module2 {
    background-color: #a52a2a00; /* 棕色背景 */
    color: rgba(255, 255, 255, 0); /* 白色文字 */
    margin: 0 8px;
   width: 200px;
    text-align: center; /* 文字居中 */
    border-radius:10px 10px  0 0 ;
    align-self: flex-start; /* 文字模块自身左对齐 */
  }



.charts-container {
  display: flex;
  justify-content: space-around; 
  align-items: center; 

}

.tradingview-chart-container {
/* margin: 10px; */
  position: relative;
  width: 400px;    
  height: 360px;  
padding: 5px 0;
border-radius: 10px;
  margin: 0 auto;  
  border: 1px solid #ddd; 
  
  background-color: #f9f9f9; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
}

.click-blocker {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; /* 遮罩层覆盖整个容器宽度 */
  height: 110px; /* 设置高度为容器高度的30% */
  background-color: transparent; /* 透明背景，不影响视觉效果 */
  z-index: 10000; /* 确保遮罩层位于容器内容之上 */
  
  background-color: transparent;
}


.logo-container {
  position: absolute;
  top: 65%;
  left: 11px;
  bottom: 0;
  z-index: 1000; 
  /* padding: 1px;  */
border-radius: 50px; 
/* padding: 20px;  */
height: 10px;
width: 10px;
}





.logo-container img {
  width: 38px; 
  height: auto;

border-radius: 50px;
}




@media (max-width: 1008px) {
  .charts-container {
    /* flex-direction: row;  */
    flex-direction: column; 
    flex-wrap: wrap; 
  }
  .tradingview-chart-container {
   
    width: 100%;
    height: 420px;  
    margin: 0;
    padding: 0;
  }
 

.text-module1{
  display: none;

}
.text-module2{
  display: none;

}
.external-container{
  width: 100%;
  margin:0;
 padding:0;
}










}

@media (min-width: 1008px) and (max-width: 1408px) {

  .container2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}



.container-image2 {
  width: 100%; 
  border-top-left-radius: 8px; /* 顶部圆角 */
  border-top-right-radius: 8px; /* 顶部圆角 */
}



.external-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start; 
  }

  .text-module1 {
    background: linear-gradient(to left, #da9e50, #cfbfab);
    color: white; /* 白色文字 */
    margin: 0 8px;
   width: 160px;
    /* text-align: center;  */
    padding: 0 20px;
    border-radius:10px 10px  0 0 ;
    align-self: flex-start; /* 文字模块自身左对齐 */
  }
  .text-module2 {
    background-color: #a52a2a00; /* 棕色背景 */
    color: rgba(255, 255, 255, 0); /* 白色文字 */
    margin: 0 8px;
   width: 200px;
    text-align: center; /* 文字居中 */
    border-radius:10px 10px  0 0 ;
    align-self: flex-start; /* 文字模块自身左对齐 */
  }



.charts-container {
  display: flex;
  justify-content: space-around; 
  align-items: center; 

}

.tradingview-chart-container {
/* margin: 10px; */
  position: relative;
  width: 280px;    
  height: 390px;  
padding: 0;
border-radius: 10px;
  margin: 0 ;  
  border: 1px solid #ddd; 
  
  background-color: #f9f9f9; 
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); 
}










}


  </style>
  