<template>
    <!-- <div id="app" :class="{ 'dark-theme': darkTheme }"> -->
      <div>
      <!-- 顶部任务栏 -->
      <nav class="top-bar">
  
       <router-link to="/">
        <img src="../images/logo1.png" alt="Logo" class="logoPH">
      </router-link>
       
        
        <!-- <TranslateButton /> -->
        
       











        
       
        <button class="menu-button" :class="{'open': isNavOpen}" @click="toggleNav">
  <span class="menu-line"></span>
  <span class="menu-line"></span>
  <span class="menu-line"></span>
</button>
  
  
      </nav>
  
   




    <div class="side-nav" :class="{ 'open': isNavOpen }">
    <div class="nav-links-container">


      <h3 class="nav-link1" @click="toggleSubMenu('menu')">
      <span class="link-title">회사소개</span>
    
      <div class="icon-container" :class="{'rotate': subMenuStates.menu}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div>


    </h3>
    <div v-if="subMenuStates.menu" class="submenu">
      <router-link to="/PageData1" class="nav-link" @click="closeNav">회사소개</router-link>
    </div>
    <hr style="width: 100%; border-color: rgb(231, 231, 231);">


    <h3 class="nav-link1" @click="toggleSubMenu('menu1')">
      <span class="link-title">국내외 증시</span>
      <div class="icon-container" :class="{'rotate': subMenuStates.menu1}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
    <div v-if="subMenuStates.menu1" class="submenu">
      <router-link to="/PageData3" class="nav-link" @click="closeNav">국내 증시</router-link>
      <router-link to="/PageData2" class="nav-link" @click="closeNav">해외 증시</router-link>
    </div>
    <hr style="width: 100%; border-color: rgb(231, 231, 231);">

    <h3 class="nav-link1" @click="toggleSubMenu('menu2')">
      <span class="link-title">실시간 시황</span>
      <div class="icon-container" :class="{'rotate': subMenuStates.menu2}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div>  </h3>
    <div v-if="subMenuStates.menu2" class="submenu">
      <router-link to="/PageData4" class="nav-link" @click="closeNav">실시간 시황</router-link>
    </div>
    <hr style="width: 100%; border-color: rgb(231, 231, 231);">

    <h3 class="nav-link1" @click="toggleSubMenu('menu3')">
      <span class="link-title">종목체크</span>
      <div class="icon-container" :class="{'rotate': subMenuStates.menu3}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
    <div v-if="subMenuStates.menu3" class="submenu">
      <router-link to="/PageData5" class="nav-link" @click="closeNav">종목종합소식</router-link>
      <router-link to="/PageData6" class="nav-link" @click="closeNav">상승종목</router-link>
      <router-link to="/PageData7" class="nav-link" @click="closeNav">급등종목</router-link>
      <router-link to="/PageData8" class="nav-link" @click="closeNav">상한가 종목</router-link>
      <router-link to="/PageData9" class="nav-link" @click="closeNav">거래량상위종목</router-link>
    </div>
    <hr style="width: 100%; border-color: rgb(231, 231, 231);">

    <h3 class="nav-link1" @click="toggleSubMenu('menu4')">
      <span class="link-title">시장지표</span>
      <div class="icon-container" :class="{'rotate': subMenuStates.menu4}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
    <div v-if="subMenuStates.menu4" class="submenu">
      <router-link to="/PageData10" class="nav-link" @click="closeNav">전종목시세</router-link>
      <router-link to="/PageData11" class="nav-link" @click="closeNav">투자주체별 동향</router-link>
      <router-link to="/PageData12" class="nav-link" @click="closeNav">외국인매매동향</router-link>
      <router-link to="/PageData13" class="nav-link" @click="closeNav">외환</router-link>
      <router-link to="/PageData14" class="nav-link" @click="closeNav">채권/금리</router-link>
      <router-link to="/PageData15" class="nav-link" @click="closeNav">국내경제지표</router-link>
    </div>
    <hr style="width: 100%; border-color: rgb(231, 231, 231);">

    <h3 class="nav-link1" @click="toggleSubMenu('menu5')">
      <span class="link-title">2024 소식지</span>
      <div class="icon-container" :class="{'rotate': subMenuStates.menu5}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div>  </h3>
    <div v-if="subMenuStates.menu5" class="submenu">
      <router-link to="/PageData16" class="nav-link" @click="closeNav">데스크 칼럼</router-link>
      <router-link to="/PageData17" class="nav-link" @click="closeNav">현장 리포터</router-link>
      <router-link to="/PageData18" class="nav-link" @click="closeNav">은행은 지금</router-link>
    </div>
    <hr style="width: 100%; border-color: rgb(231, 231, 231);">

    <h3 class="nav-link1" @click="toggleSubMenu('menu6')">
      <span class="link-title">최신 뉴스</span>
      <div class="icon-container" :class="{'rotate': subMenuStates.menu6}">
  <ion-icon name="chevron-down-outline" class="icon-right"></ion-icon>
</div> </h3>
    <div v-if="subMenuStates.menu6" class="submenu">
      <router-link to="/PageData19" class="nav-link" @click="closeNav">최신 뉴스</router-link>
    </div>
  </div>




</div>






     </div>
  
  
  
  
  
  
  
      <div class="content">
        
        <router-view></router-view> 
      </div>
  
  
  
      <br>
  <br>
  <br>
  <br>
    <!-- <hr style="border: 1px solid #00d3b7; margin: 0 20px; " >
  <br> -->
 

    <!-- </div> -->
  
    <div>
      
      <BackToTopButton />
    </div>
    
  </template>


<script>
import BackToTopButton from '../components/BackToTopButton.vue';
// import TranslateButton from '../components/TranslateButton.vue';
// import { mapState } from 'vuex';


export default {
  name: 'App',
  components: {
    BackToTopButton,
   
}, 


  data() {
    return {

      subMenuStates: {
        menu: false, 
        menu1: false,
        menu2: false,
        menu3: false,
        menu4: false,
        menu5: false,
        menu6: false,
        menu7: false,
        menu8: false,

      },


      isNavOpen: false,
      
      



    };
  },
  methods: {
   
    toggleSubMenu(menuItem) {
      Object.keys(this.subMenuStates).forEach((key) => {
      this.subMenuStates[key] = false;
    });


      this.subMenuStates[menuItem] = !this.subMenuStates[menuItem];
    },
    closeNav() {
    this.isNavOpen = false;
    document.body.classList.remove('no-scroll');
  },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
  
    }, 


  
  
    }, 


 
   

  
};



</script>

<style scoped>





.nav-link1 {
  display: flex; /* 使用flex布局 */
  justify-content: space-between; 
  align-items: center; /* 垂直居中 */
  width: 100%; /* 确保占满整个容器宽度 */
}

.link-title {
 
  text-align: left; /* 文本靠左对齐 */
}

.icon-right {
 
  margin-left: auto; /* 将图标推向右边 */
}





.submenu {
  display: flex; 
  justify-content: center; 
  width: 100%; 
  flex-direction: column;

  
}




.icon-container {
  display: inline-block;
  transition: transform 0.5s ease;
}

.rotate {
  transform: rotate(180deg);
}









.logoPH {
  /* position: absolute;
  top: 10px; 
  left: 40%;  */
  /* max-width: 30%; 
  height: 20%;  */
  display: block; 
  max-width: 150px;  
  max-height: 50px;  
  height: auto;   
  left: 3%;
  position: absolute;
  top: 0px;
}





.menu-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 40px; /* 调整宽度 */
  height: 30px; /* 调整高度 */
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
  z-index: 3000;
  margin-right: 20px;
}

.menu-line {
  display: block;
  width: 100%;
  height: 2px; 
  background: rgb(255, 255, 255); 
  transition: all 0.3s ease;
}



.menu-button.open .menu-line:nth-child(1) {
  transform: translateY(10px) rotate(45deg);
  background: rgb(0, 0, 0); 
}

.menu-button.open .menu-line:nth-child(2) {
  opacity: 0;
}

.menu-button.open .menu-line:nth-child(3) {
  transform: translateY(-10px) rotate(-45deg);
  background: rgb(0, 0, 0); 
}

</style>


