<template>
    
   
   
    
    
  <IframeComponent class="iframe-sss2" url="https://m.finance.daum.net/global/quotes/US.SP500" :iframeStyle="{ height: '1000px','border-radius': '20px', 'overflow': 'hidden' }"/>



</template>

<script>
import IframeComponent from './IframeComponent.vue'

export default {
  components: {
    IframeComponent,

  }
}
</script>

<style scoped>
.iframe-sss2{
  transform: translateY(-250px) translateX(0px);
  border-radius: 20px; 
  overflow: hidden; 
}
.iframe-sss2 iframe {
  border-radius: 20px; 
  overflow: hidden; 
}
.iframe-container {
  display: flex;
  justify-content: space-around;
   

 
}
iframe {
  width: 100%;
  border-radius: 20px; 
  overflow: hidden; 
  clip-path: inset(260px 0px 360px 0px);
overflow: hidden; 

}
@media (max-width: 768px) {
  

}

</style>
